import React, { useEffect, useState } from "react";
import queryString from "query-string";
import axios from "axios";
import LogoPus from "../assets/compro_dii.png";
import "./EmailResponse.css";
import ActivatedImage from "../assets/VerifikasiEmail.png";
import SuccessImg from "../assets/email_illus.png";

function SudahAktivasi() {
  return (
    <>
      <img src={LogoPus} alt='pusaka' className='logo-pusaka' style={{ maxWidth: '100px' }} />
      <div className='heading-1'>Email Sudah Diverifikasi</div>
      <div className='heading-2'>
        Email kamu sudah pernah diverifikasi sebelumnya. Silahkan menikmati
        setiap layanan yang telah kami sediakan!
      </div>
      <img
        src={ActivatedImage}
        className='confirmed-image'
        alt='already-confirmed'
      />
      <div>
        <a href={process.env.REACT_APP_PUSAKA_URL}>
          <button className='button__link'>Lanjutkan</button>
        </a>
      </div>
      <div className="text" >
        <p>
          Jika kamu membutuhkan informasi lebih lanjut, silahkan hubungi kami melalui <b className="text-primary">info@pusaka.id</b> atau chat WhatsApp <b className="text-primary">+6281119903441</b>
        </p>
      </div>
    </>
  );
}

function SuksesAktivasi() {
  return (
    <>
      <img src={LogoPus} alt='pusaka' className='logo-pusaka' style={{ maxWidth: '100px' }} />
      <div className='heading-1'>Verifikasi Berhasil</div>
      <div className='heading-2'>
        Sekarang E-mail kamu sudah berhasil terverifikasi. Klik tombol dibawah ini untuk langkah selanjutnya
      </div>
      <img
        src={SuccessImg}
        className='confirmed-image'
        alt='already-confirmed'
      />
      <div>
        <a href={process.env.REACT_APP_PUSAKA_URL}>
          <button className='button__link'>Lanjutkan</button>
        </a>
      </div>
      <div className="text" >
        <p>
          Jika kamu membutuhkan informasi lebih lanjut, silahkan hubungi kami melalui <b className="text-primary">info@pusaka.id</b> atau chat WhatsApp <b className="text-primary">+6281119903441</b>
        </p>
      </div>
    </>
  );
}

function EmailResponse(props) {
  const [responseData, setResponseData] = useState("");
  const values =
    props.location.search && queryString.parse(props.location.search);

  useEffect(() => {
    const validate = async () => {
      let formdata = new FormData();
      formdata.append("EmailKey", values.key);
      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}access/email/validation`,
          formdata,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((res) => res)
        .catch((err) => err.response);
      // if (response.data.status === "success") setResponseData(response?.data);
      if (response.data.status === "error") setResponseData(response?.data);
    };
    validate();
  }, [values.key]);

  return (
    <>
      {values && (
        <div className='email-response'>
          <div className='wrapper'>
            {responseData ? <SuksesAktivasi /> : <SudahAktivasi />}
          </div>
        </div>
      )}
    </>
  );
}

export default EmailResponse;
